import React, { useEffect } from "react";

const LiveChat = () => {
  useEffect(() => {
    document.title = "Live Chat";
  }, []);

  return (
    <React.Fragment>
      <div className="container">
        <div class="row">
          <div class="col-12 mx-auto text-center">
            <img
              src="/images/contact-us.jpg"
              width={300}
              className="mx-auto d-block"
            />

            <h4>We're here to assist you anytime!</h4>
            <p className="pt-2">
              Just click the circle at the bottom right to start a chat.
            </p>

            <div style={{ paddingTop: "100px" }}>
              <img src="images/bottom-arrow.png" width={200} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LiveChat;
