import React from "react";

import "./style.css";

const ServicesBoxIcon = () => {
  return (
    <section
      className="appie-service-area appie-service-3-area pt-90 pb-50"
      id="service"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="appie-section-title text-center">
              <h3 className="appie-title">Our Services</h3>
              Explore a wide range of recharge and payment solutions designed to
              <br />
              meet all your digital needs with speed, security, and convenience.
            </div>
          </div>
        </div>
        <div className="row">
          <div class="row" style={{ paddingTop: "15px" }}>
            <div class="col-md-4 col-sm-6">
              <div class="serviceBox blue">
                <div class="service-icon">
                  <span>
                    <i class="fas fa-mobile"></i>
                  </span>
                </div>
                <h3 class="title">Mobile Recharge</h3>
                <p class="description">
                  Recharge Way offers quick, secure mobile recharges for all
                  major telecom operators in India, ensuring seamless
                  connectivity anytime, anywhere.
                </p>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="serviceBox blue">
                <div class="service-icon">
                  <span>
                    <i class="fas fa-satellite-dish"></i>
                  </span>
                </div>
                <h3 class="title">DTH Recharge</h3>
                <p class="description">
                  Recharge Way provides fast and easy DTH recharges for all
                  major service providers, ensuring uninterrupted entertainment
                  at your convenience.
                </p>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="serviceBox blue">
                <div class="service-icon">
                  <span>
                    <i class="fab fa-google-play"></i>
                  </span>
                </div>
                <h3 class="title">Google Play Recharge</h3>
                <p class="description">
                  Recharge Way offers quick and secure Google Play recharges,
                  giving you instant access to apps, games, subscriptions, and
                  more, anytime you need.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesBoxIcon;
