import React from "react";

const HowItWorks = () => {
  return (
    <section
      className="appie-services-2-area appie-service-2-10-area"
      id="service"
    >
      <div className="container">
        <div className="row align-items-end">
          <div className="col-lg-12 col-md-12">
            <div className="appie-section-title">
              <h3 className="appie-title">Create an account - 3 easy steps</h3>
              <p>
                Get Started in Minutes with Our Simple Registration Process!
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div
              className="appie-single-service-2 mt-30 wow animated fadeInUp animated"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
              style={{
                visibility: "visible",
                animationDuration: "2000ms",
                animationDelay: "200ms",
                animationName: "fadeInUp",
              }}
            >
              <div className="icon">
                <i className="fas fa-sign-in" />
              </div>
              <h4 className="title">Download Application</h4>
              <p>
                Head to your app store and search for Recharge Ways App. Tap
                “Download” to get it installed on your device.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div
              className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp animated"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
              style={{
                visibility: "visible",
                animationDuration: "2000ms",
                animationDelay: "400ms",
                animationName: "fadeInUp",
              }}
            >
              <div className="icon">
                <i className="fas fa-sign-in" />
              </div>
              <h4 className="title">Quick Registration</h4>
              <p>
                Open the app and follow the easy sign-up process. Sign up free
                quickly with just a few details.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div
              className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp animated"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
              style={{
                visibility: "visible",
                animationDuration: "2000ms",
                animationDelay: "600ms",
                animationName: "fadeInUp",
              }}
            >
              <div className="icon">
                <i className="fas fa-sign-in" />
              </div>
              <h4 className="title">Start Recharging!</h4>
              <p>
                Congratulations! Your account is now ready. Start exploring all
                the features and enjoy seamless recharges.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
