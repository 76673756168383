import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";

const AboutUs = () => {
  useEffect(() => {
    document.title = "About Us - Recharge Way";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        <div className="appie-page-title-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-page-title-item">
                  <h3 className="title">About Us</h3>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        About Us
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          <section
            className="appie-service-area appie-service-3-area pt-90 pb-100"
            id="service"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h3 className="appie-title">Who We Are</h3>
                  <p className="pt-2 text-justify">
                    Recharge Way is a versatile platform dedicated to
                    simplifying mobile and DTH recharges while empowering users
                    with a rewarding experience. Our passionate team focuses on
                    creating a reliable, user-friendly solution that benefits
                    individuals and businesses alike. By prioritizing speed,
                    security, and convenience, Recharge Way is the preferred
                    choice for all your recharge needs.
                  </p>
                  <h4 className="pt-3">Our Vision</h4>
                  <p className="pt-2 text-justify">
                    Our vision is to become a leader in the recharge industry,
                    known for innovation, user satisfaction, and value. We aim
                    to transform how people stay connected, offering a seamless
                    and rewarding recharge experience for everyone.
                  </p>
                  <h4 className="pt-3">Our Mission</h4>
                  <p className="pt-2 text-justify">
                    Our mission is to deliver fast, secure, and convenient
                    recharge services, providing users with ease and added
                    value. We are committed to continuously enhancing our
                    platform, ensuring that users enjoy a seamless experience
                    while maximizing the benefits of every recharge.
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="about-image pl-5">
                    <img
                      src="images/about-us.png"
                      className="about2"
                      alt="about"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <GetInTouch /> */}
        </>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default AboutUs;
