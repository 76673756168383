import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";
import "./style.css";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";

const GrievancePolicy = () => {
  useEffect(() => {
    document.title = "Grievance Policy";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        <div className="appie-page-title-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-page-title-item">
                  <h3 className="title">Grievance Policy</h3>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Grievance Policy
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          {/* Blog Details Block */}
          <section class="appie-service-details-area pt-100 pb-100">
            <div class="container">
              <div className="section_title">
                <h4>Grievance Policy for Recharge Way</h4>

                <div className="info pt-3">
                  <p>
                    In accordance with Information Technology Act 2000 and rules
                    made there under, contact details of the Grievance Officer
                    are provided below:
                  </p>
                  {/* <p>Name:</p> */}
                  <p className="pt-2">
                    Address: No - 3 Kachari Veti Top Silbori, Dalgaon, Darrang
                    Assam - 784114
                  </p>
                  {/* <p>Mobile:</p> */}
                  <p className="pt-2">Email: help@rechargeway.in</p>
                </div>
              </div>
            </div>
          </section>
        </>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default GrievancePolicy;
