import React from "react";

const BusinessInfo = () => {
  return (
    <React.Fragment>
      <section className="appie-counter-area appie-counter-10-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="appie-single-10-counter">
                <div className="single-counter-icon">
                  <img src="assets/images/appie-count9-icon1.png" alt="" />
                </div>
                <div className="single-counter-content">
                  <h3>
                    {" "}
                    <span className="counter-item">500 K+</span>{" "}
                  </h3>
                  <p>Happy Clients</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="appie-single-10-counter">
                <div className="single-counter-icon">
                  <img src="assets/images/appie-count9-icon1.png" alt="" />
                </div>
                <div className="single-counter-content">
                  <h3>
                    {" "}
                    <span className="counter-item">500 K+</span>{" "}
                  </h3>
                  <p>App Downloads</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="appie-single-10-counter">
                <div className="single-counter-icon">
                  <img src="assets/images/appie-count9-icon1.png" alt="" />
                </div>
                <div className="single-counter-content">
                  <h3>
                    {" "}
                    <span className="counter-item">4.5 +</span>{" "}
                  </h3>
                  <p>Average Reviews</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="appie-single-10-counter">
                <div className="single-counter-icon">
                  <img src="assets/images/appie-count9-icon1.png" alt="" />
                </div>
                <div className="single-counter-content">
                  <h3>
                    {" "}
                    <span className="counter-item">400 K+</span>{" "}
                  </h3>
                  <p>Active Users</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default BusinessInfo;
